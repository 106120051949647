import logo from './logo.svg';
// import './App.css';
import React, { useRef, useEffect, useState } from 'react';

import Convert from 'ansi-to-html';
import DOMPurify from 'dompurify';
import { escape } from 'html-escaper';

const API_URI = `${process.env.REACT_APP_API_ENDPOINT}/api`;
const REFRESH_INTERVAL = 2000;

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function App() {

  const [repl, setRepl] = useState(null);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [needsScroll, setNeedsScroll] = useState(false);
  const [userQueue, setUserQueue] = useState(new Set());
  const [globalQueueCount, setGlobalQueueCount] = useState(0);

  function getNewExecutions(response) {
    if (repl == null) {
      return response;
    }

    let result = [];
    for (const r of response) {
      if (r.time > repl[repl.length-1].time) {
        result.push(r);
      }
    }
    return result;
  }

  function fetchRepl() {
    fetch(API_URI + "/fetch")
      .then(res => res.json())
      .then(
        (response) => {
          const newRepl = response.repl;
          if (needsScroll) {
            scrollToBottom();
            setNeedsScroll(false);
          }
          if (newRepl.length == 0) {
            return;
          }
          if (repl == null || newRepl[newRepl.length-1].time != repl[repl.length-1].time) {
            setTimeout(() => scrollToBottom(), 500);
            setNeedsScroll(true);
          }

          const newExecs = getNewExecutions(newRepl);
          let newQueue = new Set(userQueue);
          for (const exec of newExecs) {
            newQueue.delete(exec.value);
          }
          setUserQueue(newQueue);

          setRepl(newRepl);
          setGlobalQueueCount(response.queueCount);
        },
        (error) => {
          setError(error.toString());
        }
      )
  }

  function fetchExec() {
    textareaRef.current.value = "";
    setCode("");
    setLoading(true);
    scrollToBottom();
    fetch(API_URI + "/queue", {
      method: "POST",
      body: code
    })
      .then(async res => {
          setLoading(false);
          setError(null);
          if (res.status == 200) {
            return res.json;
          }
          throw await res.json();
        }
      )
      .then(
        (response) => {
          const newQueue = new Set(userQueue);
          newQueue.add(code);
          setUserQueue(newQueue);
          console.log("All good");
        },
        (error) => {
          if (error.error) {
            setError(error.error);
          } else {
            setError("Something went wrong");
          }
        }
      )
  }

  // useInterval(fetchRepl, REFRESH_INTERVAL);


  let data = repl;// [{"time":1683548358898,"kind":"exec","value":"print('ping')"},{"time":1683548359047,"kind":"output","value":"ping\r\n"}];

  let dataRendered = (data ?? [])
    .filter(value => {
      return !value.value.includes("82739172638217616ping"); // Filter out pings.
    })
    .map(value => {
      let time = new Date(0);
      time.setUTCSeconds(value.time / 1000);
      var convert = new Convert();
      var pure = escape(convert.toHtml(value.value));
      let color = 'border-blue-400';
      if (value.kind == "error") {
        color = 'border-red-700';
      } else if (value.kind == "output") {
        color = 'border-green-600';
      }
      return (
        <div class={`mx-4 px-2 ${color} border-l-4`} key={value.time + value.value}>
          <span class="text-gray-500 mr-4">{time.toLocaleTimeString()}</span>
          <pre class="font-mono pl-4" dangerouslySetInnerHTML={{__html: pure}}></pre>
        </div>
      );
    });

  if (dataRendered.length == 0) {
    let d = new Date();
    dataRendered = (
      <div class={`mx-4 px-2 border-red-400 border-l-4`}>
        <span class="text-gray-500 mr-4">{d.toLocaleTimeString()}</span>
        <span class="font-mono pl-4">Unfortunately the Modular team have asked me to shut this down.</span>
      </div>
    );
  }

  let maybeError = error ? (
    <div class="text-red-500 border-red-500 border-l-2  bg-red-100 m-4 p-2 inline">
      <i class="fa-solid fa-triangle-exclamation mr-2"></i>
      Error: {error}
    </div>
  ) : null;

  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const spinnerHtml = (
    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );
  const spinnerHtmlOnWhite = (
    <div class="w-full flex">
      <svg class="animate-spin m-auto h-10 w-10 text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  );


  function onEnterPress(e) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      fetchExec();
    }
  }

  const queueRendered = [...userQueue].map(q => {
    return (
      <div class={`mx-4 px-2 border-blue-400 border-l-4`} key={q}>
        <span class="font-mono pl-4">{q.replaceAll("\n", "\\n").slice(0, 100)}</span>
      </div>
    );
  });

  let hasReplLoaded = true;//data !== null;
  let execInProgress = queueRendered.length > 0;

  const btnSpinner = loading || execInProgress ? spinnerHtml : null;

  return (
    <div class="flex flex-col h-screen justify-between">
      <header class="drop-shadow-md shadow-md p-4">
        <div class="max-w-screen-xl w-full mx-auto px-4 flex ">
          <h1 class="text-3xl font-bold text-gray-700">
            Mojo 🔥 <span class="text-orange-500">Multiplayer REPL</span>
          </h1>
          {/* <button class="ml-auto text-3xl"><a class="fa-brands fa-github" href="https://github.com/dom96/mojo.picheta.me" target="_blank"></a></button> */}
        </div>
      </header>
      <main class="mb-auto max-w-screen-xl w-full mx-auto px-4 mt-4">
        <div class="text-gray-700 border-blue-400 border-l-2 m-4 p-2">
          <i class="fa-solid fa-circle-info mr-2 text-blue-600"></i>
          This website allows you to try out the <a href="https://www.modular.com/mojo" class="text-blue-400">Mojo programming language</a> for free and
          without early access to its playground. All code executions are shared in the same execution context,
          so you will see other people's code being executed and your executions will be put in a queue if demand is high.
        </div>
        <div class="text-gray-700 border-blue-400 border-l-2 m-4 p-2">
          <i class="fa-solid fa-globe mr-2 text-blue-600"></i>
          There are currently <span>{globalQueueCount}</span> executions pending.
        </div>

        <div class="my-8 ml-4 max-h-[30rem] overflow-scroll overflow-x-hidden">
          {hasReplLoaded ? dataRendered : spinnerHtmlOnWhite}
          <div ref={messagesEndRef} />
        </div>
        {/* <textarea name="code" class="border-gray-500 border-2 rounded-sm w-full max-w-[95%] mx-6 p-2 font-mono mb-6" onChange={e => setCode(e.target.value)} ref={textareaRef} onKeyDown={onEnterPress}/> */}
        {/* <button class="mb-4 mx-6 inline-flex items-center px-6 py-3 font-semibold leading-6 text-lg shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={() => fetchExec()} >
          { btnSpinner }
          { execInProgress ? "Queue Execution" : "Execute" }
        </button> */}
        {maybeError}
        { queueRendered.length > 0 ?
            <h4 class="text-lg ml-8 mt-4 border-blue-400 border-l-4 drop-shadow-md shadow-md">
              <i class="fa-solid fa-list ml-2 mr-1"></i> Your queue
            </h4>
          : null
        }
        <div class="ml-4 max-h-[10rem] overflow-scroll overflow-x-hidden  ">
          { queueRendered }
        </div>
      </main>
      <footer class="border-t-gray-300 border-0 border-t-2 text-gray-400 p-4 mt-8 text-xs text-center">
        This website is not affiliated with Modular (the developers of Mojo). <br/>I created it to help others experiment with the language.
        It is a quick weekend project so your mileage may vary. If Mojo devs would like me to shut this down then please reach out
        to <tt>dom96#7486</tt> on Discord (or <a href="https://picheta.me" class="text-blue-400">via other means</a>).
      </footer>
    </div>
  );
}

export default App;
